import {
  type SortOrder,
  type TableData,
  SearchInput,
  Table,
} from '@hummingbird/shared';
import { useState } from 'react';

import {
  Container,
  Header,
  SearchInputContainer,
  TableContainer,
} from './Users.styled';
import useUsersTable from './useUsersTable';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import useUsers from 'services/api/userProvisioning/useUsers';

const Users = () => {
  const { data: users, isLoading: areUsersLoading } = useUsers();

  const [searchInput, setSearchInput] = useState('');

  const { tableColumns, tableData, sortKey, sortOrder, setSort } =
    useUsersTable(users || [], searchInput);

  return (
    <Container>
      <Header>
        <SearchInputContainer>
          <SearchInput
            isLabelHidden
            label="Search"
            placeholder="Search"
            value={searchInput}
            onChange={(event: any) => setSearchInput(event.target.value)}
          />
        </SearchInputContainer>
      </Header>

      {areUsersLoading ? (
        <CenteredSpinner />
      ) : (
        <TableContainer>
          <Table
            columns={tableColumns}
            data={tableData}
            sortColumn={sortKey}
            sortDirection={sortOrder}
            onSort={setSort as (key: keyof TableData, order: SortOrder) => void}
          />
        </TableContainer>
      )}
    </Container>
  );
};

export default Users;
