import {
  type TableColumn,
  type TableData,
  useSort,
  useSortedData,
} from '@hummingbird/shared';

import RefreshWorkbookButton from './RefreshWorkbookButton';
import { TooltipContainer } from './WorkbooksCard.styled';

import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import type { UsePortalsAdminTable } from 'pages/PortalsAdmin/types';

type SortableColumns = 'name' | 'order';

type Props = {
  workbooks: Workbook[] | undefined;
  siteId: string;
};

const useWorkbooksTable = ({
  workbooks,
  siteId,
}: Props): UsePortalsAdminTable => {
  const { setSort, sortKey, sortOrder } = useSort<SortableColumns>();

  const tableColumns: TableColumn[] = [
    { label: 'Name', key: 'name', isSortable: true },
    {
      key: 'order',
      isSortable: true,
      label: (
        <TooltipContainer>
          Order
          <OrderTooltip />
        </TooltipContainer>
      ),
    },
    {
      key: 'action',
      label: 'action',
      isLabelHidden: true,
    },
  ];

  const tableData: TableData[] =
    workbooks?.map(({ id, name, options }) => ({
      id,
      name,
      order: options.order,
      action: <RefreshWorkbookButton siteId={siteId} workbookId={id} />,
    })) || [];

  const sortedTableData = useSortedData({
    sortKey,
    sortOrder,
    data: tableData,
  });

  return {
    tableColumns,
    tableData: sortedTableData,
    sortKey,
    sortOrder,
    setSort,
  };
};

export default useWorkbooksTable;
