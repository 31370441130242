import {
  colors,
  fontWeights,
  fontSizes,
  Folder,
  ChipButton,
} from '@hummingbird/shared';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background-color: ${colors.pearl};
  border-radius: 12px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
  margin-left: 16px;
  font-weight: ${fontWeights.mediumLarge};
  font-size: ${fontSizes.xl};
`;

export const FoldersIcon = styled(Folder)`
  height: 24px;
`;

export const AddFolderButton = styled(ChipButton).attrs({
  style: {
    height: 28,
  },
})``;
