import { UsersResponse } from 'services/api/userProvisioning/types';

const mapUsersResponseToKiskiPublishers = (users?: UsersResponse): User[] => {
  if (!users) {
    return [];
  }

  return users['KISKI-PUBLISHER']?.map(user => ({
    ...user,
    role: 'Kiski Publisher',
  }));
};

export default mapUsersResponseToKiskiPublishers;
