import { UsersResponse } from 'services/api/userProvisioning/types';

const mapUsersResponseToKiskiExplorers = (users?: UsersResponse): User[] => {
  if (!users) {
    return [];
  }

  return users['KISKI-EXPLORER']?.map(user => ({
    ...user,
    site_role: 'Kiski Explorer',
  }));
};

export default mapUsersResponseToKiskiExplorers;
