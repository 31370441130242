import { useQuery } from '@tanstack/react-query';

import { fetchFundUsers } from './requests';
import type { UsersResponse } from './types';

import { QUERY_KEYS } from 'services/constants';

const useFundUsers = (fundId: string, siteId: string) =>
  useQuery<UsersResponse>([QUERY_KEYS.FUND_USERS, fundId], () =>
    fetchFundUsers(fundId, siteId),
  );

export default useFundUsers;
