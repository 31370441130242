import { useQuery } from '@tanstack/react-query';

import { fetchSite } from './requests';

import { QUERY_KEYS } from 'services/constants';

const useSite = (siteId: string) =>
  useQuery<Site>([QUERY_KEYS.SITE, siteId], () => fetchSite(siteId));

export default useSite;
