import { QueryErrorResetBoundaryProps } from '@tanstack/react-query';

import { addNotification } from 'services/notifications';
import { isAxiosError } from 'utils/typeGuards';

type ErrorResponse = {
  detail: { msg: string }[];
};

export const handleErrorResponse = (error: QueryErrorResetBoundaryProps) => {
  if (isAxiosError(error)) {
    const errorResponseData = error.response?.data as unknown as ErrorResponse;

    const message = errorResponseData?.detail?.length
      ? errorResponseData.detail[0].msg
      : error.response?.data.message;

    addNotification({
      message,
      title: 'Error',
      type: 'danger',
    });
  }
};

export const createSiteIdHeaders = (siteId: string) => ({
  headers: {
    'x-site-id': siteId,
  },
});
