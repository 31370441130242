import { SortOrder, Table, TableData } from '@hummingbird/shared';
import { useParams } from 'react-router-dom';

import useWorkbooksTable from './useWorkbooksTable';
import { Card, CardHeader, WorkbooksIcon } from './WorkbooksCard.styled';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import useWorkbooks from 'services/api/useWorkbooks';

const WorkbooksCard = () => {
  const { siteId = '', fundId = '', folderId = '' } = useParams();

  const { data: workbooks, isLoading: areWorkbooksLoading } = useWorkbooks(
    siteId,
    fundId,
    folderId,
  );

  const { setSort, sortKey, sortOrder, tableColumns, tableData } =
    useWorkbooksTable({ workbooks, siteId });

  return (
    <Card aria-label="Workbooks">
      <CardHeader>
        <WorkbooksIcon />
        Workbooks
      </CardHeader>
      {areWorkbooksLoading ? (
        <CenteredSpinner />
      ) : (
        <Table
          columns={tableColumns}
          data={tableData}
          sortColumn={sortKey}
          sortDirection={sortOrder}
          onSort={setSort as (key: keyof TableData, order: SortOrder) => void}
        />
      )}
    </Card>
  );
};

export default WorkbooksCard;
