import { ChevronRight, IconButton } from '@hummingbird/shared';
import { useNavigate } from 'react-router-dom';

interface Props {
  to: string;
}

const ActionButton = ({ to }: Props) => {
  const navigate = useNavigate();

  return (
    <IconButton
      icon={ChevronRight}
      label="Actions"
      variant="simple"
      onClick={() => navigate(to)}
    />
  );
};

export default ActionButton;
