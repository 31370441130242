import { InfoCircle, Tooltip } from './OrderTooltip.styled';

const OrderTooltip = () => (
  <Tooltip content="Numbers in the list determine the order in which clients see the items, with lower numbers showing at the top of navigation. We recommend using numbers between 10 and 99 for easier reordering.">
    <div>
      <InfoCircle aria-label="Tooltip" />
    </div>
  </Tooltip>
);

export default OrderTooltip;
