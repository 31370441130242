import { useEffect, useState } from 'react';

const tokenName = 'kps-tkn';
const tokenExpName = 'kps-tkn-exp';

export interface AuthToken {
  id_token?: string;
  access_token?: string;
  expires_in?: number;
  expires_at?: number;
  token_type?: string;
}

const parseQueryParams = (params: string): Map<string, string> => {
  const paramsMap = params.split('&').reduce((p, c) => {
    const [a, b] = c.split('=');

    p.set(a, b);

    return p;
  }, new Map<string, string>());

  return paramsMap;
};

export const parseAuthToken = (params: string): AuthToken => {
  const data = parseQueryParams(params);
  const expiresIn = parseInt(data.get('expires_in') || '0');
  const expiresAt = Date.now() + expiresIn * 1000;

  return {
    id_token: data.get('id_token'),
    access_token: data.get('auth_token'),
    expires_in: expiresIn,
    expires_at: expiresAt,
    token_type: data.get('token_type'),
  };
};

export const clearToken = () => {
  localStorage.removeItem(tokenName);
  localStorage.removeItem(tokenExpName);
};

export const saveToken = (tkn?: AuthToken) => {
  if (tkn?.id_token) {
    localStorage.setItem(tokenName, tkn.id_token);
    localStorage.setItem(tokenExpName, `${tkn?.expires_at!}`);
  } else {
    clearToken();
  }
};

export const readToken = () => {
  const tkn = localStorage.getItem(tokenName);
  const exp = parseInt(localStorage.getItem('kps-tkn-exp') || '0');

  if (Date.now() > exp) {
    clearToken();

    return undefined;
  } else {
    return tkn ? tkn : undefined;
  }
};

export function useIdToken() {
  const [idToken, setIdToken] = useState<string | undefined>(readToken());

  useEffect(() => {
    setIdToken(readToken());
  }, []);

  return idToken || undefined;
}
