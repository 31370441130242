import {
  Button,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { Form } from './CreateFolderModal.styled';

import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';

export interface CreateFolderFormData {
  name: string;
  order: number;
}

interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (formData: CreateFolderFormData) => void;
}

const CreateFolderModal = ({ isOpen, title, onClose, onSubmit }: Props) => {
  const { formState, register, handleSubmit } = useForm<CreateFolderFormData>({
    defaultValues: {
      name: '',
    },
  });

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <ModalHeader title={title} />
      <ModalContent>
        <Form id="create-folder-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('name', {
              required: { value: true, message: 'This field is required' },
              maxLength: {
                value: NAME_MAX_LENGTH,
                message: 'Name should be less than 50 characters long',
              },
            })}
            isErrorInitiallyHidden
            error={formState.errors.name?.message}
            label="Folder name"
            placeholder="Enter folder name"
          />
          <Input
            {...register('order')}
            isErrorInitiallyHidden
            label="Order"
            max={999}
            min={1}
            placeholder="(number)"
            tooltipLabel={<OrderTooltip />}
            type="number"
          />
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined-primary" onClick={onClose}>
          Cancel
        </Button>
        <Button form="create-folder-form" type="submit">
          Create
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default CreateFolderModal;
