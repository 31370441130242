import type { UsersResponse } from 'services/api/userProvisioning/types';

const mapUsersResponseToPortalViewers = (users?: UsersResponse): User[] => {
  if (!users) {
    return [];
  }

  return (
    users['PORTAL-VIEWER-FULL-ACCESS']?.map(user => ({
      ...user,
      role: 'Portal Viewer Full Access',
    })) || []
  );
};

export default mapUsersResponseToPortalViewers;
