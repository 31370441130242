import { useQuery } from '@tanstack/react-query';

import { fetchFund } from './requests';

import { QUERY_KEYS } from 'services/constants';

const useFund = (fundId: string, siteId: string) =>
  useQuery<Fund>([QUERY_KEYS.FUNDS, fundId], () => fetchFund(fundId, siteId));

export default useFund;
