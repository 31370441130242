import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { AUTH_ENABLED } from '../constants';

import DefaultRoute from './DefaultRoute';
import PublicRoute from './PublicRoute';

import Folder from 'pages/PortalsAdmin/Sites/components/Site/components/Fund/components/Folder/Folder';
import Fund from 'pages/PortalsAdmin/Sites/components/Site/components/Fund/Fund';
import Site from 'pages/PortalsAdmin/Sites/components/Site/Site';
import Sites from 'pages/PortalsAdmin/Sites/Sites';
import Users from 'pages/PortalsAdmin/Users/Users';
import { ROUTES } from 'routes/constants';
import ProtectedRoute from 'routes/ProtectedRoute/ProtectedRoute';

/* Public routes */

const SignIn = lazy(() => import('pages/SignIn/SignIn'));

/* Protected routes */

const PortalsAdmin = lazy(() => import('pages/PortalsAdmin/PortalsAdmin'));

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <DefaultRoute />,
    children: [
      {
        index: true,
        element: <DefaultRoute />,
      },
      {
        path: ROUTES.PORTALS_ADMIN,
        element: (
          <ProtectedRoute>
            <PortalsAdmin />
          </ProtectedRoute>
        ),
        children: [
          {
            path: `${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}/:siteId${ROUTES.FUNDS}/:fundId${ROUTES.FOLDERS}/:folderId`,
            element: <Folder />,
          },
          {
            path: `${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}/:siteId${ROUTES.FUNDS}/:fundId`,
            element: <Fund />,
          },
          {
            path: `${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}/:siteId`,
            element: <Site />,
          },
          {
            path: `${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}`,
            element: <Sites />,
          },
          {
            path: `${ROUTES.PORTALS_ADMIN}${ROUTES.USERS}`,
            element: <Users />,
          },
        ],
      },

      /* Public routes */
      ...(AUTH_ENABLED
        ? [
            {
              path: ROUTES.SIGNIN,
              element: (
                <PublicRoute>
                  <SignIn />
                </PublicRoute>
              ),
            },
          ]
        : []),
    ],
  },
]);

export default router;
