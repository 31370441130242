import {
  colors,
  fontSizes,
  zIndexes,
  Close as CloseIcon,
} from '@hummingbird/shared';
import styled from 'styled-components/macro';

export const Container = styled.div`
  position: absolute;
`;

export const MenuList = styled.div.attrs({ role: 'menu' })<{
  $isBottom: boolean;
}>`
  position: absolute;
  top: ${({ $isBottom }) => ($isBottom ? '-100px' : '0')};
  left: calc(100% - 64px);
  z-index: ${zIndexes.dropdownOptions};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: 3px 3px 15px ${colors.quartz};
`;

interface MenuListItemProps {
  $color?: string;
}

export const MenuListItem = styled.div<MenuListItemProps>`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px 24px;
  ${({ $color }) => `color: ${$color || colors.navy} `};
  font-size: ${fontSizes.l};
  text-transform: capitalize;
  cursor: pointer;

  :hover,
  :focus-visible {
    text-decoration: none;
    background-color: ${colors.pearl};
  }

  > svg {
    height: 18px;
    fill: ${colors.mouse};
  }
`;

export const Close = styled(CloseIcon).attrs({
  style: {
    fill: colors.error,
  },
})``;
