import {
  ChipButton,
  colors,
  fontSizes,
  fontWeights,
} from '@hummingbird/shared';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background-color: ${colors.pearl};
  border-radius: 12px;
`;

export const TableHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  color: ${colors.pigeon};
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.xl};
  line-height: 100%;
`;

export const GrantAccessButton = styled(ChipButton).attrs({
  style: {
    height: 28,
  },
})``;
