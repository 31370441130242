import { CLIENT_PORTALS_API_PATH } from '../../../constants';
import { createSiteIdHeaders } from '../helpers';

import type {
  GrantFundAccessPayload,
  GrantSiteAccessPayload,
  RevokeFundAccessPayload,
  RevokeSiteAccessPayload,
  UserRolesResponse,
  UsersResponse,
} from './types';

import { axios } from 'config';
import { ROUTES } from 'routes/constants';

const clientPortalsPath = `${CLIENT_PORTALS_API_PATH}/admin`;

export const fetchUsers = async (): Promise<User[]> => {
  const { data } = await axios.get(`${clientPortalsPath}/users`);

  return data;
};

export const fetchSiteUsers = async (
  siteId: string,
): Promise<UsersResponse> => {
  const { data } = await axios.get(
    `${clientPortalsPath}/sites/${siteId}/users`,
  );

  return data;
};

export const fetchFundUsers = async (
  fundId: string,
  siteId: string,
): Promise<UsersResponse> => {
  const { data } = await axios.get(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}/users`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const fetchRoles = async (): Promise<UserRolesResponse> => {
  const { data } = await axios.get(
    `${clientPortalsPath}/users/provision/options`,
  );

  return data;
};

export const grantSiteAccess = async ({
  email,
  siteId,
  role,
}: GrantSiteAccessPayload): Promise<User> => {
  const { data } = await axios.post(`${clientPortalsPath}/users/provision`, {
    emails: [email],
    site_id: siteId,
    role,
    admin: false,
  });

  return data;
};

export const revokeSiteAccess = async ({
  email,
  siteId,
}: RevokeSiteAccessPayload): Promise<User> => {
  const { data } = await axios.delete(`${clientPortalsPath}/users/provision`, {
    data: {
      emails: [email],
      site_id: siteId,
    },
  });

  return data;
};

export const grantFundAccess = async ({
  email,
  siteId,
  fundId,
}: GrantFundAccessPayload): Promise<User> => {
  const { data } = await axios.post(`${clientPortalsPath}/users/provision`, {
    emails: [email],
    site_id: siteId,
    fund_id: fundId,
    role: 'FUND-VIEWER',
    admin: false,
  });

  return data;
};

export const revokeFundAccess = async ({
  email,
  siteId,
  fundId,
}: RevokeFundAccessPayload): Promise<User> => {
  const { data } = await axios.delete(`${clientPortalsPath}/users/provision`, {
    data: {
      emails: [email],
      site_id: siteId,
      fund_id: fundId,
    },
  });

  return data;
};
