import {
  colors,
  fontWeights,
  fontSizes,
  FinancialStatement,
  IconButton,
} from '@hummingbird/shared';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background-color: ${colors.pearl};
  border-radius: 12px;
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
  margin-left: 16px;
  font-weight: ${fontWeights.mediumLarge};
  font-size: ${fontSizes.xl};
`;

export const WorkbooksIcon = styled(FinancialStatement)`
  height: 24px;
`;

export const TableActionButton = styled(IconButton)`
  margin-left: auto;
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;
