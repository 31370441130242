import { useQuery } from '@tanstack/react-query';

import { fetchFunds } from './requests';

import { QUERY_KEYS } from 'services/constants';

const useFunds = (siteId: string) =>
  useQuery<Fund[]>([QUERY_KEYS.FUNDS, siteId], () => fetchFunds(siteId));

export default useFunds;
