import {
  Bin,
  Button,
  IconButton,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import useDeleteFund from 'services/api/useDeleteFund';

interface Props {
  fund: Fund;
}

const DeleteFundButton = ({ fund }: Props) => {
  const { siteId = '' } = useParams();
  const { mutate: deleteFund } = useDeleteFund(siteId);

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnConfirm = () => {
    deleteFund({ siteId, fundId: fund.id });
    closeModal();
  };

  return (
    <>
      <IconButton
        icon={Bin}
        label="Delete"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal2 isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader
          title={`Are you sure you want to delete "${fund.name}" fund?`}
        />
        <ModalContent>Fund and all of its content will be deleted</ModalContent>
        <ModalFooter>
          <Button variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleOnConfirm}>Yes, I am sure</Button>
        </ModalFooter>
      </Modal2>
    </>
  );
};

export default DeleteFundButton;
