import { useOnClickOutside } from '@hummingbird/shared';
import {
  Root,
  Trigger,
  Portal,
  TooltipContentProps,
} from '@radix-ui/react-tooltip';
import { useCallback, useEffect, useRef, useState } from 'react';

import { TooltipContent, TooltipArrow } from './Tooltip.styled';

// Docs: https://www.radix-ui.com/docs/primitives/components/tooltip

interface TooltipProps extends Omit<TooltipContentProps, 'content'> {
  children: string | JSX.Element;
  content: React.ReactNode;
  defaultOpen?: boolean;
  isDisabled?: boolean;
  open?: boolean;
  isClickMode?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const Tooltip = ({
  children,
  content,
  defaultOpen,
  isDisabled,
  isClickMode,
  open,
  onOpenChange,
  ...contentProps
}: TooltipProps) => {
  const [isOpenByClick, setOpenByClick] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(contentRef, () => {
    if (isClickMode) setOpenByClick(false);
  });

  const handleKeyDown = useCallback(
    (
      event: KeyboardEvent | React.KeyboardEvent,
      isDefaultPrevented?: boolean,
    ) => {
      if (['Escape', 'Enter', ' '].includes(event.key) && isOpenByClick) {
        if (isDefaultPrevented) event.preventDefault();
        setOpenByClick(false);
      }
    },
    [isOpenByClick],
  );

  useEffect(() => {
    document.body?.addEventListener('keydown', handleKeyDown);

    () => {
      document.body?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isDisabled) return <>{children}</>;

  return (
    <Root
      defaultOpen={defaultOpen}
      open={isClickMode ? isOpenByClick : open}
      onOpenChange={onOpenChange}>
      <Trigger
        asChild
        onClick={() => isClickMode && setOpenByClick(prevValue => !prevValue)}
        onKeyDown={event => handleKeyDown(event, true)}>
        {children}
      </Trigger>
      <Portal>
        <TooltipContent ref={contentRef} {...contentProps}>
          {content}
          <TooltipArrow />
        </TooltipContent>
      </Portal>
    </Root>
  );
};

export default Tooltip;
