import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { deleteFolder } from './requests';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useDeleteFolder = (
  fundId: string,
  options?: UseMutationLocalOptions<Folder, DeleteFolderPayload>,
) =>
  useMutation(deleteFolder, {
    ...options,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([QUERY_KEYS.FOLDERS, fundId]);

      addNotification({
        message: 'Folder successfully deleted!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useDeleteFolder;
