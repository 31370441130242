import {
  type TableColumn,
  type TableData,
  useSort,
  useSortedData,
} from '@hummingbird/shared';

import getActionsByUserRole from './getActionsByUserRole';

import type { UsePortalsAdminTable } from 'pages/PortalsAdmin/types';

const tableColumns: TableColumn[] = [
  { label: 'Email', key: 'email', isSortable: true },
  { label: 'Site Role', key: 'siteRole', isSortable: true },
  {
    key: 'actions',
    label: 'Actions',
    isLabelHidden: true,
  },
];

type SortableColumns = 'email' | 'siteRole';

const useUsersTable = (
  users: User[],
  onRevoke: (email: string) => void,
  isSite: boolean,
): UsePortalsAdminTable => {
  const { setSort, sortKey, sortOrder } = useSort<SortableColumns>();

  const tableData: TableData[] =
    users?.map(user => ({
      id: user.user_id,
      email: user.email,
      siteRole: user.site_role,
      actions: getActionsByUserRole(user, onRevoke, isSite),
    })) || [];

  const sortedTableData = useSortedData({
    sortKey,
    sortOrder,
    data: tableData,
  });

  return {
    tableColumns,
    tableData: sortedTableData,
    sortKey,
    sortOrder,
    setSort,
  };
};

export default useUsersTable;
