import { useMutation } from '@tanstack/react-query';

import { updateFolder } from './requests';

import { UseMutationLocalOptions } from 'config/queryClient';
import { addNotification } from 'services/notifications';

const useUpdateFolder = (
  options?: UseMutationLocalOptions<Folder, UpdateFolderPayload>,
) =>
  useMutation(updateFolder, {
    ...options,
    onSuccess(data, variables, context) {
      addNotification({
        message: 'Folder successfully updated!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });

export default useUpdateFolder;
