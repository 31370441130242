import { InfoCircle as BaseInfoCircle } from '@hummingbird/shared';
import styled from 'styled-components';

import BaseTooltip from 'components/Tooltip/Tooltip';

export const Tooltip = styled(BaseTooltip)`
  max-width: 220px;
`;

export const InfoCircle = styled(BaseInfoCircle)`
  height: 16px;
`;
