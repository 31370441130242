export const QUERY_KEYS = {
  FOLDERS: 'client-portals-folders',
  FUNDS: 'funds',
  FUND_USERS: 'fund-users',
  ROLES: 'roles',
  SITES: 'sites',
  SITE: 'site',
  SITE_USERS: 'site-users',
  USERS: 'users',
  WORKBOOKS: 'client-portals-workbooks',
};
