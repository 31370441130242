import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from '../helpers';

import { grantSiteAccess } from './requests';
import type { GrantSiteAccessPayload } from './types';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useGrantSiteAccess = (
  options?: UseMutationLocalOptions<User, GrantSiteAccessPayload>,
) =>
  useMutation(grantSiteAccess, {
    ...options,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([QUERY_KEYS.SITE_USERS]);

      addNotification({
        message: 'Access successfully granted!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useGrantSiteAccess;
