import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { createSite } from './requests';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useCreateSite = (
  options?: UseMutationLocalOptions<Site, CreateSitePayload>,
) =>
  useMutation(createSite, {
    ...options,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([QUERY_KEYS.SITES]);

      addNotification({
        message: 'Successfully created!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useCreateSite;
