import {
  Plus,
  Table,
  type SortOrder,
  type TableData,
} from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import mapUsersResponseToKiskiExplorers from '../../../../../utils/mapUsersResponseToKiskiExplorers';
import mapUsersResponseToKiskiPublishers from '../../../../../utils/mapUsersResponseToKiskiPublishers';
import mapUsersResponseToKiskiViewers from '../../../../../utils/mapUsersResponseToKiskiViewers';
import mapUsersResponseToPortalViewers from '../../../../../utils/mapUsersResponseToPortalViewers/mapUsersResponseToPortalViewers';
import useUsersTable from '../../../../../utils/useUsersTable';

import { Card, GrantAccessButton, TableHeader } from './SiteUsersCard.styled';
import UsersCardHeader from './UsersCardHeader/UsersCardHeader';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import GrantSiteAccessModal, {
  type AccessFormData,
} from 'pages/PortalsAdmin/Sites/components/Site/components/SiteUsersCard/GrantSiteAccessForm/GrantSiteAccessModal';
import useGrantSiteAccess from 'services/api/userProvisioning/useGrantSiteAccess';
import useRevokeSiteAccess from 'services/api/userProvisioning/useRevokeSiteAccess';
import useSiteUsers from 'services/api/userProvisioning/useSiteUsers';
import useSite from 'services/api/useSite';
import { addNotification } from 'services/notifications';

const SiteUsersCard = () => {
  const { siteId = '' } = useParams();

  const { data: site } = useSite(siteId);
  const { data: users, isLoading: areUsersLoading } = useSiteUsers(siteId);

  const { mutate: grantAccess } = useGrantSiteAccess();
  const { mutate: revokeAccess } = useRevokeSiteAccess();

  const [isGrantAccessModalOpen, setGrantAccessModalOpen] = useState(false);
  const [role, setRole] = useState<string>();

  const portalViewers = mapUsersResponseToPortalViewers(users);
  const kiskiViewers = mapUsersResponseToKiskiViewers(users);
  const kiskiExplorers = mapUsersResponseToKiskiExplorers(users);
  const kiskiPublishers = mapUsersResponseToKiskiPublishers(users);

  const handleRevokeAccess = (email: string) => {
    if (!site?.id) {
      return;
    }

    revokeAccess({ email, siteId: site.id });
  };

  const {
    tableColumns: kiskiViewersTableColumns,
    tableData: kiskiViewersTableData,
    sortKey: kiskiViewersSortKey,
    sortOrder: kiskiViewersSortOrder,
    setSort: kiskiViewersSetSort,
  } = useUsersTable(kiskiViewers, handleRevokeAccess, true);

  const {
    tableColumns: portalViewersTableColumns,
    tableData: portalViewersTableData,
    sortKey: portalViewersSortKey,
    sortOrder: portalViewersSortOrder,
    setSort: portalViewersSetSort,
  } = useUsersTable(portalViewers, handleRevokeAccess, true);

  const {
    tableColumns: kiskiExplorersTableColumns,
    tableData: kiskiExplorersTableData,
    sortKey: kiskiExplorersSortKey,
    sortOrder: kiskiExplorersSortOrder,
    setSort: kiskiExplorersSetSort,
  } = useUsersTable(kiskiExplorers, handleRevokeAccess, true);

  const {
    tableColumns: kiskiPublishersTableColumns,
    tableData: kiskiPublishersTableData,
    sortKey: kiskiPublishersSortKey,
    sortOrder: kiskiPublishersSortOrder,
    setSort: kiskiPublishersSetSort,
  } = useUsersTable(kiskiPublishers, handleRevokeAccess, true);

  const handleGrantAccessFormOnSubmit = (data: AccessFormData) => {
    setGrantAccessModalOpen(false);

    if (!site?.id || !role) {
      return;
    }

    const payload = {
      siteId: site.id,
      email: data.email,
      role: data.role || role,
    };

    grantAccess(payload, {
      onError: () => {
        addNotification({
          message: '',
          title: 'Error',
          type: 'danger',
        });
      },
    });

    setRole(undefined);
  };

  return (
    <>
      <Card aria-label="Site Users">
        <UsersCardHeader />
        {areUsersLoading ? (
          <CenteredSpinner />
        ) : (
          <>
            <TableHeader>
              Portal Viewers
              <GrantAccessButton
                aria-label="Add Portal Viewer"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  setGrantAccessModalOpen(true);
                  setRole('PORTAL-VIEWER');
                }}>
                Add Portal Viewer
              </GrantAccessButton>
            </TableHeader>
            {!!portalViewersTableData.length && (
              <Table
                aria-label="Portal Viewers"
                columns={portalViewersTableColumns}
                data={portalViewersTableData}
                sortColumn={portalViewersSortKey}
                sortDirection={portalViewersSortOrder}
                onSort={
                  portalViewersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
            <TableHeader>
              Kiski Viewers
              <GrantAccessButton
                aria-label="Add Kiski Viewer"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  setGrantAccessModalOpen(true);
                  setRole('KISKI-VIEWER');
                }}>
                Add Kiski Viewer
              </GrantAccessButton>
            </TableHeader>
            {!!kiskiViewersTableData.length && (
              <Table
                aria-label="Kiski Viewers"
                columns={kiskiViewersTableColumns}
                data={kiskiViewersTableData}
                sortColumn={kiskiViewersSortKey}
                sortDirection={kiskiViewersSortOrder}
                onSort={
                  kiskiViewersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
            <TableHeader>
              Kiski Explorers
              <GrantAccessButton
                aria-label="Add Kiski Explorer"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  setGrantAccessModalOpen(true);
                  setRole('KISKI-EXPLORER');
                }}>
                Add Kiski Explorer
              </GrantAccessButton>
            </TableHeader>
            {!!kiskiExplorersTableData.length && (
              <Table
                aria-label="Kiski Explorers"
                columns={kiskiExplorersTableColumns}
                data={kiskiExplorersTableData}
                sortColumn={kiskiExplorersSortKey}
                sortDirection={kiskiExplorersSortOrder}
                onSort={
                  kiskiExplorersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
            <TableHeader>
              Kiski Publishers
              <GrantAccessButton
                aria-label="Add Kiski Publisher"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  setGrantAccessModalOpen(true);
                  setRole('KISKI-PUBLISHER');
                }}>
                Add Kiski Publisher
              </GrantAccessButton>
            </TableHeader>
            {!!kiskiPublishersTableData.length && (
              <Table
                aria-label="Kiski Publishers"
                columns={kiskiPublishersTableColumns}
                data={kiskiPublishersTableData}
                sortColumn={kiskiPublishersSortKey}
                sortDirection={kiskiPublishersSortOrder}
                onSort={
                  kiskiPublishersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
          </>
        )}
      </Card>

      <GrantSiteAccessModal
        isEditMode={false}
        isOpen={isGrantAccessModalOpen}
        role={role || ''}
        title={`Grant Site Access - ${site?.portal_name}`}
        onClose={() => setGrantAccessModalOpen(false)}
        onSubmit={handleGrantAccessFormOnSubmit}
      />
    </>
  );
};

export default SiteUsersCard;
