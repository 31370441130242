import {
  Button,
  Checkbox,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { Form } from './CreateSiteModal.styled';

import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';

export interface CreateSiteFormData {
  name: string;
  domain: string;
  visible: boolean;
  externalId: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: CreateSiteFormData) => void;
}

const CreateSiteModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const { formState, register, handleSubmit } = useForm<CreateSiteFormData>({
    defaultValues: {
      name: '',
      domain: '',
      visible: false,
      externalId: '',
    },
  });

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Create New Site" />
      <ModalContent>
        <Form id="create-site-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('name', {
              required: { value: true, message: 'This field is required' },
              maxLength: {
                value: NAME_MAX_LENGTH,
                message: 'Name should be less than 50 characters long',
              },
            })}
            isErrorInitiallyHidden
            error={formState.errors.name?.message}
            label="Site name"
            placeholder="Enter site name"
          />
          <Input
            {...register('domain')}
            isErrorInitiallyHidden
            label="Domain"
            placeholder=".portal.kiski.com"
          />
          <Checkbox {...register('visible')} label="Visible" />
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined-primary" onClick={onClose}>
          Cancel
        </Button>
        <Button form="create-site-form" type="submit">
          Submit
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default CreateSiteModal;
