import { Spinner, User } from '@hummingbird/shared';
import { useParams } from 'react-router-dom';

import { Container } from './UsersCardHeader.styled';

import useFund from 'services/api/useFund';

const UsersCardHeader = () => {
  const { siteId = '', fundId = '' } = useParams();

  const { data: fund, isLoading: isFundLoading } = useFund(fundId, siteId);

  return (
    <Container>
      <User height={24} />
      {isFundLoading ? (
        <Spinner aria-label="Spinner" />
      ) : (
        <>Users Access to {fund?.name}</>
      )}
    </Container>
  );
};

export default UsersCardHeader;
