import { useQuery } from '@tanstack/react-query';

import { fetchFolder } from './requests';

import { QUERY_KEYS } from 'services/constants';

const useFolder = (folderId: string, fundId: string, siteId: string) =>
  useQuery<Folder>([QUERY_KEYS.FOLDERS, folderId], () =>
    fetchFolder(folderId, fundId, siteId),
  );

export default useFolder;
