import { useQuery } from '@tanstack/react-query';

import { fetchWorkbooks } from './requests';

import { QUERY_KEYS } from 'services/constants';

const useWorkbooks = (siteId: string, fundId: string, folderId: string) =>
  useQuery<Workbook[]>([QUERY_KEYS.WORKBOOKS, folderId], () =>
    fetchWorkbooks(siteId, fundId, folderId),
  );

export default useWorkbooks;
