import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { createFund } from './requests';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useCreateFund = (
  options?: UseMutationLocalOptions<Fund, CreateFundPayload>,
) =>
  useMutation(createFund, {
    ...options,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([QUERY_KEYS.FUNDS]);

      addNotification({
        message: 'Successfully created!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useCreateFund;
