import { colors, fontWeights } from '@hummingbird/shared';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 16px;
  background-color: ${colors.white};
`;

export const NavigatonBar = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 16px 0 0 16px;
  padding: 16px 0 0 16px;
  font-weight: ${fontWeights.mediumLarge};
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 32px;
  margin: 32px;
  overflow: scroll;
`;
