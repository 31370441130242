import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';
import { ROUTES } from 'routes/constants';

interface Props {
  children: JSX.Element;
}

const PublicRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const { isLoggedIn, isInitialized } = useAuthContext();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.PORTALS_ADMIN, { replace: true });
    }
  }, [isInitialized, isLoggedIn, navigate]);

  return isInitialized && !isLoggedIn ? children : null;
};

export default PublicRoute;
