import {
  Button,
  Eye,
  EyeDisabled,
  IconButton,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useState } from 'react';

import useUpdateSite from 'services/api/useUpdateSite';

interface Props {
  site: Site;
}

const VisibilityButton = ({ site }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { mutate: updateSite } = useUpdateSite();

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChangeSiteVisibility = () => {
    if (!site) {
      return;
    }

    const { hidden, id } = site;

    updateSite({
      id,
      payload: {
        hidden: !hidden,
        name: site.portal_name,
      },
    });

    closeModal();
  };

  const isHidden = !!site?.hidden;

  const message = isHidden
    ? 'Users will be able to see this Site'
    : 'Users will not be able to see this Site anymore';

  return (
    <>
      <IconButton
        icon={isHidden ? EyeDisabled : Eye}
        label="visibility"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal2 isOpen={!!isModalOpen} onClose={closeModal}>
        <ModalHeader
          title={`Are you sure you want to make the Site ${
            isHidden ? 'visible' : 'invisible'
          }?`}
        />
        <ModalContent>{message}</ModalContent>

        <ModalFooter>
          <Button variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleChangeSiteVisibility}>Yes, I am sure</Button>
        </ModalFooter>
      </Modal2>
    </>
  );
};

export default VisibilityButton;
