import { Button, Plus, Spinner } from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CreateFolderModal, {
  type CreateFolderFormData,
} from './components/CreateFolderModal/CreateFolderModal';
import FoldersCard from './components/FoldersCard/FoldersCard';
import FundTitle from './components/FundTitle/FundTitle';
import FundUsersCard from './components/FundUsersCard/FundUsersCard';
import { CardsContainer, Container, Header, NavigatonBar } from './Fund.styled';

import BackButton from 'pages/PortalsAdmin/components/BackButton/BackButton';
import useCreateFolder from 'services/api/useCreateFolder';
import useFund from 'services/api/useFund';
import useSite from 'services/api/useSite';

const Fund = () => {
  const { siteId = '', fundId = '' } = useParams();

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);
  const { data: fund, isLoading: isFundLoading } = useFund(fundId, siteId);

  const { mutate: createFolder } = useCreateFolder();

  const [isCreateFolderModalOpen, setCreateFolderlModalOpen] = useState(false);

  const closeCreateFolderlModal = () => {
    setCreateFolderlModalOpen(false);
  };

  const handleOnSubmit = (formData: CreateFolderFormData) => {
    const { name, order } = formData;

    const payload: CreateFolderPayload = {
      siteId,
      fundId,
      payload: {
        name,
        options: {
          hidden: false,
          order,
        },
      },
    };

    closeCreateFolderlModal();
    createFolder(payload);
  };

  return (
    <Container>
      <Header>
        <NavigatonBar>
          <BackButton />
          {isFundLoading || isSiteLoading || !fund ? (
            <Spinner aria-label="Spinner" />
          ) : (
            <FundTitle fund={fund} site={site!} />
          )}
        </NavigatonBar>
        <Button icon={Plus} onClick={() => setCreateFolderlModalOpen(true)}>
          Create New Folder
        </Button>
      </Header>

      <CardsContainer>
        <FoldersCard />
        <FundUsersCard />
      </CardsContainer>

      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        title={`Create New Folder in ${fund?.name}`}
        onClose={closeCreateFolderlModal}
        onSubmit={handleOnSubmit}
      />
    </Container>
  );
};

export default Fund;
