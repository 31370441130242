import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { createFolder } from './requests';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useCreateFolder = (
  options?: UseMutationLocalOptions<Folder, CreateFolderPayload>,
) =>
  useMutation(createFolder, {
    ...options,
    onSuccess(data, variables, context) {
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.FOLDERS]);
      }, 1000);

      addNotification({
        message: 'Successfully created!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useCreateFolder;
