import { UsersResponse } from 'services/api/userProvisioning/types';

const mapUsersResponseToKiskiViewers = (users?: UsersResponse): User[] => {
  if (!users) {
    return [];
  }

  return users['KISKI-VIEWER']?.map(user => ({
    ...user,
    role: 'Kiski Viewer',
  }));
};

export default mapUsersResponseToKiskiViewers;
