import { CLIENT_PORTALS_API_PATH } from '../../constants';

import { createSiteIdHeaders } from './helpers';

import { axios } from 'config';
import { ROUTES } from 'routes/constants';

const clientPortalsPath = `${CLIENT_PORTALS_API_PATH}/admin`;

export const fetchSites = async (): Promise<Site[]> => {
  const { data } = await axios.get(
    `${clientPortalsPath}/sites?page=1&limit=1000`,
  );

  return data;
};

export const fetchSite = async (siteId: string): Promise<Site> => {
  const { data } = await axios.get(`${clientPortalsPath}/sites/${siteId}`);

  return data;
};

export const createSite = async (payload: CreateSitePayload): Promise<Site> => {
  const { data } = await axios.post(`${clientPortalsPath}/sites`, payload);

  return data;
};

export const updateSite = async ({
  id,
  payload,
}: UpdateSitePayload): Promise<Site> => {
  const { data } = await axios.put(`${clientPortalsPath}/sites/${id}`, payload);

  return data;
};

export const deleteSite = async ({ id }: DeleteSitePayload): Promise<Site> => {
  const { data } = await axios.delete(`${clientPortalsPath}/sites/${id}`);

  return data;
};

export const fetchFunds = async (siteId: string): Promise<Fund[]> => {
  const { data } = await axios.get(
    `${clientPortalsPath}/funds`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const fetchFund = async (
  fundId: string,
  siteId: string,
): Promise<Fund> => {
  const { data } = await axios.get(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const createFund = async ({
  siteId,
  payload,
}: CreateFundPayload): Promise<Fund> => {
  const { data } = await axios.post(
    `${clientPortalsPath}/funds`,
    payload,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const updateFund = async ({
  id,
  siteId,
  payload,
}: UpdateFundPayload): Promise<Fund> => {
  const { data } = await axios.put(
    `${clientPortalsPath}/funds/${id}`,
    payload,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const deleteFund = async ({
  siteId,
  fundId,
}: DeleteFundPayload): Promise<Fund> => {
  const { data } = await axios.delete(
    `${clientPortalsPath}/funds/${fundId}`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const fetchFolders = async (
  siteId: string,
  fundId: string,
): Promise<Folder[]> => {
  const { data } = await axios.get(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}${ROUTES.FOLDERS}`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const fetchFolder = async (
  folderId: string,
  fundId: string,
  siteId: string,
): Promise<Folder> => {
  const { data } = await axios.get(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}${ROUTES.FOLDERS}/${folderId}`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const createFolder = async ({
  siteId,
  fundId,
  payload,
}: CreateFolderPayload): Promise<Folder> => {
  const { data } = await axios.post(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}${ROUTES.FOLDERS}`,
    payload,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const updateFolder = async ({
  id,
  fundId,
  siteId,
  payload,
}: UpdateFolderPayload): Promise<Folder> => {
  const { data } = await axios.put(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}${ROUTES.FOLDERS}/${id}`,
    payload,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const deleteFolder = async ({
  siteId,
  fundId,
  folderId,
}: DeleteFolderPayload): Promise<Folder> => {
  const { data } = await axios.delete(
    `${clientPortalsPath}/funds/${fundId}/folders/${folderId}`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const fetchWorkbooks = async (
  siteId: string,
  fundId: string,
  folderId: string,
): Promise<Workbook[]> => {
  const { data } = await axios.get(
    `${clientPortalsPath}${ROUTES.FUNDS}/${fundId}${ROUTES.FOLDERS}/${folderId}/workbooks`,
    createSiteIdHeaders(siteId),
  );

  return data;
};

export const refreshWorkbook = async ({
  siteId,
  workbookId,
}: RefreshWorkbookPayload): Promise<unknown> => {
  const { data } = await axios.post(
    `${clientPortalsPath}/sites/${siteId}/workbooks/${workbookId}/refresh`,
    createSiteIdHeaders(siteId),
  );

  return data;
};
