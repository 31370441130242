import { HeaderContainer, Image } from './Header.styled';

import logo from 'logo.svg';

interface HeaderProps {
  title: string;
  subtitle?: string;
}

const Header = (props: HeaderProps) => {
  const { title, subtitle } = props;

  return (
    <HeaderContainer>
      <Image alt="Kiski Group, LLC" src={logo} />
      <h1>
        {title}
        {subtitle ? ` - ${subtitle}` : ''}
      </h1>
      <div></div>
    </HeaderContainer>
  );
};

export default Header;
