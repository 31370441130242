import { colors, fontWeights } from '@hummingbird/shared';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 16px;
  background-color: ${colors.white};
`;

export const NavigatonBar = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 32px;
  margin: 32px;
  overflow: scroll;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 16px 0;
  padding: 16px 16px 0;
  font-weight: ${fontWeights.mediumLarge};
`;
