import { BackArrow, Pencil } from '@hummingbird/shared';
import { styled } from 'styled-components';

export const Icon = styled(Pencil)`
  height: 24px;
`;

export const TitleInputContainer = styled.div`
  display: flex;
  width: fit-content;
  min-width: 300px;
`;

export const ArrowIcon = styled(BackArrow)`
  height: 24px;
  transform: rotate(180deg);
`;
