import { Spinner, User } from '@hummingbird/shared';
import { useParams } from 'react-router-dom';

import { Container } from './UsersCardHeader.styled';

import useSite from 'services/api/useSite';

const UsersCardHeader = () => {
  const { siteId = '' } = useParams();

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);

  return (
    <Container>
      <User height={24} />
      {isSiteLoading ? (
        <Spinner aria-label="Spinner" />
      ) : (
        <>All {site?.portal_name} Users</>
      )}
    </Container>
  );
};

export default UsersCardHeader;
