export const ROUTES = {
  FOLDERS: '/folders',
  FUNDS: '/funds',
  HOME: '/',
  PORTALS_ADMIN: '/portals-admin',
  PORTFOLIOS: '/portfolios',
  SIGNIN: '/signin',
  SITES: '/sites',
  USERS: '/users',
};

export const PUBLIC_ROUTES = [ROUTES.SIGNIN];
