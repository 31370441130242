import {
  Button,
  Dropdown,
  DropdownOption,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { Controller, useForm } from 'react-hook-form';

import { Form } from './ModifyAccess.styled';

const roles = ['KISKI-PUBLISHER', 'KISKI-VIEWER', 'PORTAL-VIEWER'];

interface FormData {
  email: string;
  role: string;
}

interface Props {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
}

const ModifyAccess = ({ user, isOpen, onClose, onSubmit }: Props) => {
  const { control, register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: user.email,
      role:
        user.site_role === 'Kiski Viewer' ? 'KISKI-VIEWER' : 'KISKI-PUBLISHER',
    },
  });

  const dropdownOptions: DropdownOption[] =
    roles.map(role => ({ label: role, value: role })) || [];

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Modify access" />
      <ModalContent>
        <Form id="modify-access-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('email')}
            isDisabled
            isErrorInitiallyHidden
            label="Email"
          />
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value } }) => (
              <Dropdown
                inputProps={{
                  label: 'Role',
                  placeholder: 'Enter role',
                  isErrorInitiallyHidden: true,
                }}
                options={dropdownOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined-primary" onClick={onClose}>
          Cancel
        </Button>
        <Button form="modify-access-form" type="submit">
          Apply
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default ModifyAccess;
