import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { queryClient } from 'config';
import { clearToken, readToken } from 'utils/htmlHelpers';

export interface AuthContextState {
  isInitialized: boolean;
  isLoggedIn: boolean;
  setLoggedIn: (isLoggedIn: boolean) => void;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextState>({
  isInitialized: false,
  isLoggedIn: false,
  setLoggedIn: () => null,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isInitialized, setInitialized] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const hasLoggedIn = useRef(false);

  useEffect(() => {
    const accessToken = readToken();

    try {
      if (accessToken) {
        // TODO: add refresh token call once implemented
        setLoggedIn(true);
      }
    } catch (error) {
      queryClient.clear();
      clearToken();
    } finally {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) queryClient.clear();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !hasLoggedIn.current) hasLoggedIn.current = true;

    if (hasLoggedIn.current && !isLoggedIn) {
      clearToken();
    }
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider value={{ isInitialized, isLoggedIn, setLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
