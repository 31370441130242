import { ChipButton } from '@hummingbird/shared';

import UserMenu from '../components/UsersMenu/UserMenu';

const getActionsByUserRole = (
  user: User,
  onRevoke: (email: string) => void,
  isSite: boolean,
): React.ReactNode => {
  const isKiskiUser = user.site_role.includes('Kiski');
  const isFundUser = user.site_role.includes('Fund');

  const isFundPage = !isSite;

  if (isFundPage) {
    return (
      <ChipButton variant="outlined" onClick={() => onRevoke(user.email)}>
        Revoke Access
      </ChipButton>
    );
  }

  if (isKiskiUser) {
    return isSite ? <UserMenu user={user} onRevoke={onRevoke} /> : null;
  }

  return isFundUser ? null : (
    <ChipButton variant="outlined" onClick={() => onRevoke(user.email)}>
      Revoke Access
    </ChipButton>
  );
};

export default getActionsByUserRole;
