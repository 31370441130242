import { Spinner } from '@hummingbird/shared';
import { useParams } from 'react-router-dom';

import FolderTitle from './components/FolderTitle/FolderTitle';
import WorkbooksCard from './components/WorkbooksCard/WorkbooksCard';
import {
  CardsContainer,
  Container,
  Header,
  NavigatonBar,
} from './Folder.styled';

import BackButton from 'pages/PortalsAdmin/components/BackButton/BackButton';
import useFolder from 'services/api/useFolder';
import useFund from 'services/api/useFund';
import useSite from 'services/api/useSite';

const Folder = () => {
  const { siteId = '', fundId = '', folderId = '' } = useParams();

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);
  const { data: fund, isLoading: isFundLoading } = useFund(fundId, siteId);

  const { data: folder, isLoading: isFolderLoading } = useFolder(
    folderId,
    fundId,
    siteId,
  );

  return (
    <Container>
      <Header>
        <NavigatonBar>
          <BackButton />
          {isFundLoading ||
          isSiteLoading ||
          isFolderLoading ||
          !folder ||
          !fund ||
          !site ? (
            <Spinner aria-label="Spinner" />
          ) : (
            <FolderTitle folder={folder} fund={fund} site={site} />
          )}
        </NavigatonBar>
      </Header>
      <CardsContainer>
        <WorkbooksCard />
      </CardsContainer>
    </Container>
  );
};

export default Folder;
