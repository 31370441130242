import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { deleteSite } from './requests';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useDeleteSite = (
  options?: UseMutationLocalOptions<Site, DeleteSitePayload>,
) =>
  useMutation(deleteSite, {
    ...options,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([QUERY_KEYS.SITES]);

      addNotification({
        message: 'Site successfully deleted!',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useDeleteSite;
