import { BackArrow, IconButton } from '@hummingbird/shared';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      icon={BackArrow}
      label="back"
      size="small"
      variant="outlined"
      onClick={() => navigate(-1)}
    />
  );
};

export default BackButton;
