import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';
import { ROUTES } from 'routes/constants';
import ProtectedLayout from 'routes/ProtectedRoute/ProtectedLayout/ProtectedLayout';

interface Props {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const { isInitialized, isLoggedIn } = useAuthContext();

  useEffect(() => {
    if (isInitialized && !isLoggedIn) {
      navigate(ROUTES.SIGNIN, { replace: true });
    }
  }, [isInitialized, isLoggedIn, navigate]);

  return isLoggedIn ? <ProtectedLayout>{children}</ProtectedLayout> : null;
};

export default ProtectedRoute;
