import { Spinner } from '@hummingbird/shared';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import FundsCard from './components/FundsCard/FundsCard';
import SiteTitle from './components/SiteTitle/SiteTitle';
import SiteUsersCard from './components/SiteUsersCard/SiteUsersCard';
import { CardsContainer, Container, NavigatonBar } from './Site.styled';

import BackButton from 'pages/PortalsAdmin/components/BackButton/BackButton';
import useSite from 'services/api/useSite';
import { QUERY_KEYS } from 'services/constants';

const Site = () => {
  const { siteId = '' } = useParams();
  const queryClient = useQueryClient();

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);

  useEffect(
    () => () => {
      queryClient.invalidateQueries([QUERY_KEYS.SITE, siteId]);
    },
    [siteId, queryClient],
  );

  return (
    <Container>
      <NavigatonBar>
        <BackButton />
        {isSiteLoading ? (
          <Spinner aria-label="Spinner" />
        ) : (
          <SiteTitle site={site!} />
        )}
      </NavigatonBar>
      <CardsContainer>
        <FundsCard />
        <SiteUsersCard />
      </CardsContainer>
    </Container>
  );
};

export default Site;
