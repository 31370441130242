import {
  Button,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { Form } from './GrantFundAccessModal.styled';

export interface FundAccessFormData {
  email: string;
}

interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (formData: FundAccessFormData) => void;
}

const GrantFundAccessModal = ({ isOpen, title, onClose, onSubmit }: Props) => {
  const { register, handleSubmit } = useForm<FundAccessFormData>({
    defaultValues: {
      email: '',
    },
  });

  return (
    <Modal2 isOpen={isOpen} onClose={onClose}>
      <ModalHeader title={title} />
      <ModalContent>
        <Form id="grant-fund-access-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('email')}
            isErrorInitiallyHidden
            label="Email"
            placeholder="Enter email"
          />
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined-primary" onClick={onClose}>
          Cancel
        </Button>
        <Button form="grant-fund-access-form" type="submit">
          Apply
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default GrantFundAccessModal;
