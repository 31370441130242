import {
  Plus,
  Table,
  type SortOrder,
  type TableData,
} from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import useFoldersTable from '../../useFoldersTable/useFoldersTable';
import CreateFolderModal, {
  type CreateFolderFormData,
} from '../CreateFolderModal/CreateFolderModal';

import {
  AddFolderButton,
  Card,
  CardHeader,
  FoldersIcon,
  TitleContainer,
} from './FoldersCard.styled';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import useCreateFolder from 'services/api/useCreateFolder';
import useFolders from 'services/api/useFolders';
import useFund from 'services/api/useFund';
import useSite from 'services/api/useSite';

const FoldersCard = () => {
  const { siteId = '', fundId = '' } = useParams();

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);
  const { data: fund, isLoading: isFundLoading } = useFund(fundId, siteId);
  const { data: folders, isLoading: areFoldersLoading } = useFolders(
    siteId,
    fundId,
  );

  const { mutate: createFolder, isLoading: isFolderCreating } =
    useCreateFolder();

  const { tableColumns, tableData, sortKey, sortOrder, setSort } =
    useFoldersTable(folders || [], site, fund);

  const [isCreateFolderModalOpen, setCreateFolderlModalOpen] = useState(false);

  const closeCreateFolderlModal = () => {
    setCreateFolderlModalOpen(false);
  };

  const handleOnSubmit = (formData: CreateFolderFormData) => {
    const { name, order } = formData;

    const payload: CreateFolderPayload = {
      siteId,
      fundId,
      payload: {
        name,
        options: {
          hidden: false,
          order: order || undefined,
        },
      },
    };

    closeCreateFolderlModal();
    createFolder(payload);
  };

  const isLoading = isSiteLoading || isFundLoading || isFolderCreating;

  return (
    <>
      <Card aria-label="Folders">
        <CardHeader>
          <TitleContainer>
            <FoldersIcon />
            Folders in {fund?.name}
          </TitleContainer>
          <AddFolderButton
            aria-label="Add Folder"
            icon={Plus}
            iconPosition="left"
            onClick={() => setCreateFolderlModalOpen(true)}>
            Add Folder
          </AddFolderButton>
        </CardHeader>
        {isLoading || areFoldersLoading || isFolderCreating ? (
          <CenteredSpinner />
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            sortColumn={sortKey}
            sortDirection={sortOrder}
            onSort={setSort as (key: keyof TableData, order: SortOrder) => void}
          />
        )}
      </Card>

      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        title={`Create New Folder in ${fund?.name}`}
        onClose={closeCreateFolderlModal}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

export default FoldersCard;
