import { useCallback } from 'react';

import EditableOrderCell from 'pages/PortalsAdmin/components/EditableOrderCell/EditableOrderCell';
import useUpdateFund from 'services/api/useUpdateFund';

interface Props {
  fund: Fund;
  siteId: string;
}

const FundOrder = ({ fund, siteId }: Props) => {
  const { mutate: updateFund } = useUpdateFund();

  const handleOnUpdate = useCallback(
    (order: number) => {
      const payload: UpdateFundPayload = {
        id: fund.id,
        siteId,
        payload: {
          name: fund.name,
          options: {
            ...fund.options,
            order,
          },
        },
      };

      updateFund(payload);
    },
    [fund, siteId, updateFund],
  );

  return (
    <EditableOrderCell
      name="Site Fund"
      value={fund.options.order}
      onUpdate={handleOnUpdate}
    />
  );
};

export default FundOrder;
