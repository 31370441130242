import defaultAxiosInstance from 'axios';

import { PUBLIC_ROUTES, ROUTES } from 'routes/constants';
import { readToken, clearToken } from 'utils/htmlHelpers';

const axios = defaultAxiosInstance.create();

axios.interceptors.request.use(instance => {
  const token = readToken();

  if (instance?.headers) {
    if (token) {
      instance.headers.Authorization = `Bearer ${token || ''}`;
    }

    return instance;
  }
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      clearToken();

      if (!PUBLIC_ROUTES.includes(document.location.pathname)) {
        document.location.pathname = ROUTES.SIGNIN;
      }
    }

    return Promise.reject(error);
  },
);

export { defaultAxiosInstance };
export default axios;
