import { colors } from '@hummingbird/shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
`;

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  background-color: ${colors.white};
  border-radius: 12px;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  min-width: 400px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
