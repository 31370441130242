import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 16px;
  font-size: 24px;
`;

export const Image = styled.img`
  width: auto;
  height: 32px;
`;
