import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { refreshWorkbook } from './requests';

import { UseMutationLocalOptions } from 'config/queryClient';
import { addNotification } from 'services/notifications';
import { isAxiosError } from 'utils/typeGuards';

const useRefreshWorkbook = (
  options?: UseMutationLocalOptions<unknown, RefreshWorkbookPayload>,
) =>
  useMutation(refreshWorkbook, {
    ...options,
    onSuccess(data, variables, context) {
      addNotification({
        message:
          'Workbook refresh initiated successfully. Please check back later.',
        title: 'Success',
        type: 'success',
      });

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: error => {
      if (
        isAxiosError(error) &&
        (error?.response?.status === 400 || error.code === 'ERR_NETWORK')
      ) {
        addNotification({
          message: 'Unable to refresh. This workbook is not using an extract',
          title: 'Error',
          type: 'danger',
        });

        return;
      }

      handleErrorResponse(error);
    },
  });

export default useRefreshWorkbook;
