import {
  useSort,
  useSortedData,
  type TableColumn,
  type TableData,
} from '@hummingbird/shared';

import ActionButton from '../../components/ActionButton/ActionButton';
import EditableCell from '../../components/EditableCell/EditableCell';
import type { UsePortalsAdminTable } from '../../types';

import DeleteSiteButton from './DeleteSiteButton';
import VisibilityButton from './VisibilityButton';

import { ROUTES } from 'routes/constants';
import useUpdateSite from 'services/api/useUpdateSite';

const tableColumns: TableColumn[] = [
  { label: 'Visible', key: 'isVisible' },
  { label: 'Site Name', key: 'portalName' },
  { label: 'Domain', key: 'domain' },
  { label: 'Delete', key: 'delete', isLabelHidden: true },
  {
    key: 'actions',
    label: 'Actions',
    isLabelHidden: true,
  },
];

type SortableColumns = 'domain';

const useSitesTable = (
  sites: Site[],
  searchInput: string,
): UsePortalsAdminTable => {
  const { setSort, sortKey, sortOrder } = useSort<SortableColumns>();

  const { mutate: updateSite } = useUpdateSite();

  const tableData: TableData[] = sites.map(site => ({
    id: site.id,
    isVisible: <VisibilityButton site={site} />,
    portalName: (
      <EditableCell
        value={site.portal_name}
        onUpdate={name => {
          updateSite({
            id: site.id,
            payload: {
              name,
            },
          });
        }}
      />
    ),
    domain: (
      <EditableCell
        value={site.domain || ''}
        onUpdate={domain => {
          updateSite({
            id: site.id,
            payload: {
              name: site.portal_name,
              domain: domain || undefined,
            },
          });
        }}
      />
    ),
    delete: <DeleteSiteButton site={site} />,
    actions: (
      <ActionButton to={`${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}/${site.id}`} />
    ),
  }));

  const filteredTableData = tableData.filter(tableRow => {
    const site = sites.find(s => s.id === tableRow.id);

    if (!site) {
      return false;
    }

    const portalName = site.portal_name.toLowerCase();
    const domain = site.domain?.toLowerCase();
    const clientId = site.external_id?.toLowerCase();

    const lowerCaseSearchInput = searchInput.toLowerCase();

    return (
      portalName?.includes(lowerCaseSearchInput) ||
      clientId?.includes(lowerCaseSearchInput) ||
      domain?.includes(lowerCaseSearchInput)
    );
  });

  const sortedTableData = useSortedData({
    sortKey,
    sortOrder,
    data: filteredTableData,
  });

  return {
    tableColumns,
    tableData: sortedTableData,
    sortKey,
    sortOrder,
    setSort,
  };
};

export default useSitesTable;
