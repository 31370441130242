import { Input, Spinner } from '@hummingbird/shared';
import { useCallback, useState } from 'react';

import { ArrowIcon, Icon, TitleInputContainer } from './FundTitle.styled';

import { queryClient } from 'config';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';
import useUpdateFund from 'services/api/useUpdateFund';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

interface Props {
  fund: Fund;
  site: Site;
}

const FundTitle = ({ fund, site }: Props) => {
  const [isEditingMode, setEditingMode] = useState(false);
  const [fundName, setFundName] = useState(fund.name);

  const closeEditingMode = () => {
    setEditingMode(false);
  };

  const { mutate: updateFund, isLoading: isFundUpdating } = useUpdateFund({
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.FUNDS, site.id]);
    },
  });

  const updateTitleIfChanged = useCallback(() => {
    const didNameChanged = fundName !== fund.name;

    if (!didNameChanged) {
      closeEditingMode();

      return;
    }

    if (fundName.length > NAME_MAX_LENGTH) {
      addNotification({
        message: 'Name should be less than 50 characters long',
        title: 'Error',
        type: 'danger',
      });
    } else {
      const payload = {
        id: fund.id,
        siteId: site.id,
        payload: {
          name: fundName,
        },
      };

      updateFund(payload);
    }

    closeEditingMode();
  }, [site.id, fund.id, fund.name, fundName, updateFund]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        closeEditingMode();
      } else if (event.key === 'Enter') {
        updateTitleIfChanged();
      }
    },
    [updateTitleIfChanged],
  );

  if (isFundUpdating) {
    return <Spinner aria-label="Spinner" />;
  }

  if (isEditingMode) {
    return (
      <TitleInputContainer>
        <Input
          autoFocus
          isErrorInitiallyHidden
          isLabelHidden
          elementSize="small"
          label="title"
          value={fundName}
          onBlur={updateTitleIfChanged}
          onChange={e => setFundName(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
      </TitleInputContainer>
    );
  }

  return (
    <>
      {site.portal_name} <ArrowIcon /> {fund?.name}
      <Icon
        aria-label="Edit title"
        onClick={() => {
          setFundName(fund.name);
          setEditingMode(true);
        }}
      />
    </>
  );
};

export default FundTitle;
