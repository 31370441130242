import { NavLink } from './NavigationBarItem.styled';

interface Props {
  name: string;
  route: string;
}

const NavigationBarItem = ({ name, route }: Props) => (
  <NavLink to={route}>{name}</NavLink>
);

export default NavigationBarItem;
