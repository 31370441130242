import { SearchInput } from '@hummingbird/shared';

import { Container } from './SitesSearchInput.styled';

interface OnChangeEvent {
  target: { value: string };
}

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const SitesSearchInput = ({ value, onChange }: Props) => (
  <Container>
    <SearchInput
      isLabelHidden
      label="Search"
      placeholder="Search"
      value={value}
      onChange={(event: OnChangeEvent) => onChange(event.target.value)}
    />
  </Container>
);

export default SitesSearchInput;
