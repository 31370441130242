import { useMutation } from '@tanstack/react-query';

import { handleErrorResponse } from './helpers';
import { updateFund } from './requests';

import queryClient, { UseMutationLocalOptions } from 'config/queryClient';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

const useUpdateFund = (
  options?: UseMutationLocalOptions<Fund, UpdateFundPayload>,
) =>
  useMutation(updateFund, {
    ...options,
    onSuccess(data, variables, context) {
      addNotification({
        message: 'Fund successfully updated!',
        title: 'Success',
        type: 'success',
      });

      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.FUNDS]);
      }, 1000);

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: handleErrorResponse,
  });

export default useUpdateFund;
