import { objectEntries } from '@hummingbird/shared';

import { UsersResponse } from 'services/api/userProvisioning/types';

const nonFundUsersKeys = [
  'KISKI-VIEWER',
  'KISKI-EXPLORER',
  'KISKI-PUBLISHER',
  'All Users',
  'PORTAL-VIEWER-FULL-ACCESS',
];

const mapUsersResponseToFundViewers = (users?: UsersResponse): User[] => {
  if (!users) {
    return [];
  }

  const entries = objectEntries(users);

  return entries.reduce<User[]>((acc, curr) => {
    const key = curr[0] as string;
    const value = curr[1];

    if (nonFundUsersKeys.includes(key)) {
      return acc;
    }

    const mappedUsers = value.map(user => ({
      ...user,
      role: `Fund ${user.site_role}`,
    }));

    return [...acc, ...mappedUsers];
  }, []);
};

export default mapUsersResponseToFundViewers;
