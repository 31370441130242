import { Content, ContentWrapper } from './ProtectedLayout.styled';

import Header from 'components/Header/Header';
import NavBar from 'components/NavigationBar/NavigationBar';

interface LayoutProps {
  children: JSX.Element;
  subTitle?: string;
}

const DEFAULT_TITLE = 'KPS-HDS Admin Console';

const ProtectedLayout = (props: LayoutProps) => {
  const { subTitle } = props;

  return (
    <ContentWrapper>
      <Header subtitle={subTitle} title={DEFAULT_TITLE} />
      <NavBar />
      <Content>{props.children}</Content>
    </ContentWrapper>
  );
};

export default ProtectedLayout;
