import {
  type TableColumn,
  type TableData,
  useSort,
  useSortedData,
} from '@hummingbird/shared';

import type { UsePortalsAdminTable } from '../types';

const tableColumns: TableColumn[] = [
  { label: 'Email', key: 'email', isSortable: true },
  { label: 'Site role', key: 'siteRole', isSortable: true },
];

type SortableColumns = 'email' | 'siteRole';

const useUsersTable = (
  users: User[],
  searchInput: string,
): UsePortalsAdminTable => {
  const { setSort, sortKey, sortOrder } = useSort<SortableColumns>();

  const tableData: TableData[] = users.map(user => ({
    id: user.user_id,
    email: user.email,
    siteRole: user.site_role,
  }));

  const filteredTableData = tableData.filter(tableRow => {
    const row = tableRow as unknown as {
      email: string;
      siteRole: string;
    };

    const email = row.email.toLowerCase();
    const siteRole = row.siteRole.toLowerCase();

    const lowerCaseSearchInput = searchInput.toLowerCase();

    return (
      email.includes(lowerCaseSearchInput) ||
      siteRole.includes(lowerCaseSearchInput)
    );
  });

  const sortedTableData = useSortedData({
    sortKey,
    sortOrder,
    data: filteredTableData,
  });

  return {
    tableColumns,
    tableData: sortedTableData,
    sortKey,
    sortOrder,
    setSort,
  };
};

export default useUsersTable;
