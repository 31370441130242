import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import queryClient from 'config/queryClient';
import EditableOrderCell from 'pages/PortalsAdmin/components/EditableOrderCell/EditableOrderCell';
import useUpdateFolder from 'services/api/useUpdateFolder';
import { QUERY_KEYS } from 'services/constants';

interface Props {
  fundId: string;
  folder: Folder;
}

const FolderOrder = ({ folder }: Props) => {
  const { siteId = '', fundId = '' } = useParams();

  const { mutate: updateFolder } = useUpdateFolder({
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.FOLDERS, fundId]);
      }, 1000);
    },
  });

  const handleOnUpdate = useCallback(
    (order: number) => {
      updateFolder({
        id: folder.id,
        siteId,
        fundId,
        payload: {
          name: folder.name,
          options: {
            ...folder.options,
            order: order || 0,
          },
        },
      });
    },
    [folder.id, folder.name, folder.options, fundId, siteId, updateFolder],
  );

  return (
    <EditableOrderCell
      name="Folder"
      value={folder.options.order}
      onUpdate={handleOnUpdate}
    />
  );
};

export default FolderOrder;
