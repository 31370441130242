import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';
import { ROUTES } from 'routes/constants';

const DefaultRoute = () => {
  const navigate = useNavigate();
  const { isInitialized, isLoggedIn } = useAuthContext();

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    navigate(isLoggedIn ? ROUTES.PORTALS_ADMIN : ROUTES.SIGNIN, {
      replace: true,
    });
  }, [isInitialized, isLoggedIn, navigate]);

  return null;
};

export default DefaultRoute;
