import { useQuery } from '@tanstack/react-query';

import { fetchFolders } from './requests';

import { QUERY_KEYS } from 'services/constants';

const useFolders = (siteId: string, fundId: string) =>
  useQuery<Folder[]>([QUERY_KEYS.FOLDERS, fundId], () =>
    fetchFolders(siteId, fundId),
  );

export default useFolders;
