import { useQuery } from '@tanstack/react-query';

import { fetchSiteUsers } from './requests';
import type { UsersResponse } from './types';

import { QUERY_KEYS } from 'services/constants';

const useSiteUsers = (siteId: string) =>
  useQuery<UsersResponse>([QUERY_KEYS.SITE_USERS, siteId], () =>
    fetchSiteUsers(siteId),
  );

export default useSiteUsers;
