import { Input } from '@hummingbird/shared';
import { type KeyboardEvent, useCallback, useState, useEffect } from 'react';

import {
  EditableInputContainer,
  ChangeIcon,
  Container,
  ReadOnlyInputContainer,
} from './EditableCell.styled';

import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';
import { addNotification } from 'services/notifications';

interface Props {
  value: string;
  onUpdate: (value: string) => void;
}

const EditableCell = ({ value, onUpdate }: Props) => {
  const [isEditing, setEditing] = useState(false);
  const [updatedValue, setUpdatedValue] = useState('');

  const openEditingMode = () => {
    setEditing(true);
  };

  const closeEditingMode = () => {
    setEditing(false);
  };

  const resetToInitialValue = useCallback(() => {
    closeEditingMode();
    setUpdatedValue(value);
  }, [value]);

  // Whenever site name is updated, initial site name should be updated here
  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  const handleOnBlur = useCallback(() => {
    const didValueChanged = updatedValue !== value;

    if (didValueChanged && updatedValue.length) {
      if (updatedValue.length > NAME_MAX_LENGTH) {
        addNotification({
          message: 'Name should be less than 50 characters long',
          title: 'Error',
          type: 'danger',
        });
      } else {
        onUpdate(updatedValue);
      }
    }

    resetToInitialValue();
  }, [onUpdate, resetToInitialValue, updatedValue, value]);

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const didValueChanged = updatedValue !== value;

      const isEscapeClicked = event.key === 'Escape';
      const isEnterClicked = event.key === 'Enter';

      const shouldResetToInitialValue =
        isEscapeClicked || (isEnterClicked && !didValueChanged);

      if (shouldResetToInitialValue) {
        resetToInitialValue();

        return;
      }

      if (isEnterClicked && didValueChanged) {
        onUpdate(updatedValue);
        closeEditingMode();
      }
    },
    [onUpdate, resetToInitialValue, updatedValue, value],
  );

  return (
    <Container>
      {isEditing ? (
        <EditableInputContainer>
          <Input
            autoFocus
            isErrorInitiallyHidden
            isLabelHidden
            elementSize="small"
            label="order"
            value={updatedValue}
            onBlur={handleOnBlur}
            onChange={e => setUpdatedValue(e.target.value)}
            onKeyDown={handleOnKeyDown}
          />
        </EditableInputContainer>
      ) : (
        <ReadOnlyInputContainer>
          {value}
          <ChangeIcon onClick={openEditingMode} />
        </ReadOnlyInputContainer>
      )}
    </Container>
  );
};

export default EditableCell;
